import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import double_triangle from "../../images/pyramid/double_triangle.svg";

const Pyramid4 = () =>(
    <section className="pyramid-5">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>What’s the Secret that Makes The Pyramid so Effective?</h2>
                    <p>The Pyramid has thousands of people excited to work out again. The magic lies in the constant stimulation of your muscles, which is totally different to most workouts where your muscles are ‘switched off’ half the time.</p>
                </Col>
            </Row>
            <Row className="d-md-none grey-bg align-items-center">
                <Col xs="4">
                    <img src={double_triangle} className="img-fluid" alt="" />
                </Col>
                <Col xs="8">
                    <p>The Pyramid is a special form of training that strengthens the entire body and burning fatty tissue.</p>
                </Col>
            </Row>            
            <Row className="d-none d-md-block">
                <Col md={{size: 10, offset: 1}}>
                    <p>The key to activating and keeping your muscles ‘switched on’ is the special timing and combination of exercises. The Pyramid strengthens your entire body and kicks your body into fat-burning overdrive to deliver fast, visible results.</p>
                </Col>
            </Row>
            <Row className="d-md-none">
                <Col md={{size: 10, offset: 1}}>
                    <p>The key to activating and keeping your muscles ‘switched on’ is the special timing and combination of exercises. The Pyramid strengthens your entire body and kicks your body into fat-burning overdrive to deliver fast, visible results.</p>
                </Col>
            </Row>                
        </Container>
    </section>
);

export default Pyramid4;

import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

const Pyramid2 = () =>(
    <section className="pyramid-2">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>A New Workout Designed to Specifically Burn More Fat & Gain More Energy</h2>
                    <p>The Pyramid is a revolutionary new type of ‘progressive training’. Amazingly, in just one 12-minute session you get the same results as a 45-minute workout at the gym!</p>
                    <p>By combining multiple exercises and changing duration it keeps your body guessing to maximise fat burn and muscle development. Best of all, you can start noticing results in as little as 7 days!</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Pyramid2;

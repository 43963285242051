import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import fire from "../../images/pyramid/fire-outline.svg";
import support from "../../images/pyramid/support-outline.svg";
import map from "../../images/pyramid/map-outline.svg";
import triangle from "../../images/pyramid/triangle.svg";
import scrollToElement from '../../helpers/scrollToElement';

const Pyramid4 = (props) =>(
    <section className="pyramid-6">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Even More Benefits of The Pyramid…</h2>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}}  md={{size: 1, offset: 1}}>
                    <img src={fire} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>MAXIMISE CALORIES BURNED</strong><br/>
                    This training is based on special exercises that have been proven to make your body burn the maximum level of calories by activating all muscle groups.</p>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}} md={{size: 1, offset: 1}}>
                    <img src={support} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>NEVER LOSE YOUR MOJO & MOTIVATION TO EXERCISE</strong><br/>
                    Fast, visible results put a smile on your face and give you the perfect buzz to go further! And with new dynamic workouts daily, you’ll never lose motivation!</p>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}} md={{size: 1, offset: 1}}>
                    <img src={map} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>WORK OUT ANYWHERE, ANYTIME</strong><br/>
                    Workout to your own training schedule! Want to work out at home, in the park or on vacation? No problem – even without Internet!</p>
                </Col>
            </Row>

            <Row className="d-md-none mb-5">
                <Col>
                    <div className="line mt-5">
                        <span>
                            <div
                                className="round-button"
                                onClick={() =>
                                    scrollToElement(
                                        document.getElementById('piramida2')
                                    )
                                }
                            >
                                <img src={triangle} alt="" />
                            </div>         
                        </span>
                    </div>
                </Col>
            </Row>

            <Row id="piramida2">
                <Col md={{size: 10, offset: 1}}>
               
                    <p><strong>MAXIMISE CALORIES BURNED</strong><br/>This training is based on special exercises that have been proven to make your body burn the maximum level of calories by activating all muscle groups.</p>

                    <h2>Forget What You’ve Been Told, Workouts Don’t have to be Long & Complicated…</h2>
                    <p>Achieving your dream of a slim figure, more energy and confidence doesn’t have to take forever in the gym.</p>
                    <p>The Pyramid is powerfully simple, but incredibly effective. It’s the exact, easy-to-follow plan you need – one that’ll make you love your body!</p>

                    <div className="line mt-5 d-none d-md-block">
                        <span className="d-inline">
                            <a href={props.url} className="btn btn-primary">Install now</a>
                        </span>
                    </div>

                    <a href={props.url} className="btn btn-primary d-md-none mt-5">Install now</a>
                </Col>
            </Row>
                          
        </Container>
    </section>
);

export default Pyramid4;

import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

const Pyramid1 = (props) => (
        <section className="pyramid-1" id="piramida1">
            <Container>
                <Row>
                    <Col xs={{size: 12, offset: 0}} md={{size: 10, offset: 1}}>
                        <h2>Easily Burn 192 calories in 12 minutes without Ever Stepping into a Gym</h2>
                        <p>Discover the new workout that’s got 100,000s of people saying bye-bye to dull, time-consuming workouts and tearing up gym memberships.</p>
                        <br />
                        <h2>Short on Time? Frustrated by Boring Gym Sessions? This New Workout Could Be the Answer to Your Prayers…</h2>
                        <p>When you imagine your dream body, what do you see… a flat belly, firm butt, toned arms and legs? Let’s face it, this usually means hours slogging away in the gym. Who’s got time for that these days? Now there’s an easier way to get the slim figure you’re dreaming of. And it takes just 12 minutes a day…</p>
    
                        <div className="text-center mt-5">
                            <a href={props.url} className="btn btn-primary">Try it now</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
);

export default Pyramid1;

import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import training1 from "../../images/pyramid/AL_training_gif1_opti.gif";
import training2 from "../../images/pyramid/AL_training_gif2_opti.gif";
import process from "../../images/pyramid/process-en.svg";
import pyramid from "../../images/pyramid/hpba-piramida-new-a.svg";
import pyramidMobile from "../../images/pyramid/hpba-piramida-new-mobile.svg";
import fire from "../../images/pyramid/fire.svg";

const Pyramid3 = () =>(
    <section className="pyramid-3">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>THE PYRAMID - How it Works</h2>
                    <p>Pyramid training features two highly-effective ways to work out: progressively increase then decrease exercise intensity and duration; or combine multiple types of exercises.</p>
                    <p><strong>Step 1</strong> – Pyramid training features two highly-effective ways to work out: progressively increase then decrease exercise intensity and duration; or combine multiple types of exercises.</p>
                </Col>
            </Row>

            <Row>
                <Col md={{size: 6, offset: 3}}>
                    <img src={process} className="img-fluid my-4" alt="" />
                </Col>
                <Col xs={{size: 6, offset: 0}} md={{size: 3, offset: 3}}>
                    <img src={training1} className="img-fluid" alt="" />
                </Col>
                <Col xs={{size: 6, offset: 0}} md="3">
                    <img src={training2} className="img-fluid" alt="" />
                </Col>
            </Row>
    
            <Row className="mt-3">
                <Col md={{size: 10, offset: 1}} className="text-center">
                    <p><strong>Step 2</strong> – Repeat the exercises according to the pyramid diagram below to maximise fat burn.</p>
                    <img src={pyramid} alt="" className="img-fluid d-none d-md-block" />
                    <img src={pyramidMobile} alt="" className="img-fluid d-md-none" />
                    

                    <div className="success">
                        <Row className="align-items-center">
                            <Col xs="3" md="2"><img src={fire} className="img-fluid" alt="" /></Col>
                            <Col xs="9" md="10">
                                You did it! You burnt an incredible <strong>192 calories</strong> in&nbsp;just 12 minutes!
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>  
                
        </Container>
    </section>
);

export default Pyramid3;

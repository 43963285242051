import React from 'react';
import { Container } from 'reactstrap';
import Img from "gatsby-image";
import triangle from "../../images/pyramid/triangle.svg";
import scrollToElement from '../../helpers/scrollToElement';

const Hero = (props) => (
    <section className="hero">
        <Container>
            <Img fluid={props.hero} />
            <div
                className="round-button"
                onClick={() =>
                    scrollToElement(
                        document.getElementById('piramida1')
                    )
                }
            >
                <img src={triangle} alt="" />
            </div>
        </Container>
    </section>
);
    
export default Hero;
